import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <AuthLogin3 />
        },
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/auth/login/',
            element: <AuthLogin3 />
        }
        // {
        //     path: '/auth/register/',
        //     element: <AuthRegister3 />
        // }
    ]
};

export default AuthenticationRoutes;
