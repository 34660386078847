const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4
};

export default config;

export const APIURI = 'https://api.escapelygames.com/';
// export const APIURI = 'https://es-api-prod.immodesta.com/';
export const REDIRECTURI = 'https://escapelygames.com/';

// export const APIURI = 'http://ec2-18-217-152-232.us-east-2.compute.amazonaws.com:5000/';
// export const APIURI = 'https://api-escapley.immodesta.com/';
// export const REDIRECTURI = 'https://devuser.escapelygames.com/';

// dev aws config
//   export const awsConfig = {
//         bucketName: 'esacpelydev',
//         dirName: 'media',
//         region: 'us-east-2',
//         accessKeyId: 'AKIAXKR5FNP3ATOUDS3F',
//         secretAccessKey: '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',
//         s3Url: 'https://esacpelydev.s3.us-east-2.amazonaws.com'
//     };

// prod old aws config
// export const awsConfig = {
//     bucketName: 'escapely',
//     dirName: 'media',
//     region: 'us-west-1',
//     accessKeyId: 'AKIAXKR5FNP3CIQPZGGJ',
//     secretAccessKey: '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',
//     s3Url: 'https://escapely.s3.us-west-1.amazonaws.com'
// };

// prod new updated aws config
export const awsConfig = {
    headers: { 'Access-Control-Allow-Origin': '*' },
    bucketName: 'escapely',
    dirName: 'media',
    region: 'us-west-1',
    accessKeyId: 'AKIAXKR5FNP3F3WMGLPV',
    secretAccessKey: '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',
    s3Url: 'https://escapely.s3.us-west-1.amazonaws.com'
};
