import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const icons = {
    AccountCircleIcon
};

const Users = {
    id: 'users',
    title: 'Users',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.AccountCircleIcon,
            breadcrumbs: false
        }
    ]
};

export default Users;
