import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import Game from './games';
import settings from './settings';
import report from './report';
import Users from './users';
import GamesHistory from './history';
import Pods from './pods';
// import PodHistory from './podhistory';
// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [dashboard, Game, GamesHistory, Users, settings, report, Pods]
};

export default menuItems;
