// assets
import { IconReport } from '@tabler/icons';

// constant
const icons = {
    IconReport
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const report = {
    id: 'settings',
    title: 'Report',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            url: '/report',
            icon: icons.IconReport,
            breadcrumbs: false
        }
    ]
};

export default report;
